/* eslint-disable no-undef */
const handleStudentTeacherKidsPopup = () => {
  const popupId = 'student-teacher-kids-popup';
  const studentTeacherKidsPopup = document.getElementById(popupId);

  const bindPopupEvents = () => {
    const ctaButtons = document.querySelectorAll(
      '.js-student-teacher-kids-popup-button'
    );

    ctaButtons.forEach((button, index) => {
      button.addEventListener('click', e => {
        if (index === 0) e.preventDefault();
        button.setAttribute('data-close', popupId);
      });
    });
  };

  const openStudentTeacherKidsPopup = () => {
    const expirationDate = new Date();
    const $studentTeacherKidsPopup = $(`#${popupId}`);

    const revealOptions = { closeOnClick: true };

    const revealStudentTeacherKidsPopup = new Foundation.Reveal(
      $studentTeacherKidsPopup,
      revealOptions
    );

    revealStudentTeacherKidsPopup.open();

    bindPopupEvents();

    expirationDate.setDate(expirationDate.getDate() + 30);

    document.cookie =
      '_sofatutor_student_teacher_kids_popup=seen; expires=' +
      expirationDate.toUTCString() +
      '; path=/';
  };

  const checkCookieConsent = () => {
    if (document.cookie.includes('_sofatutor_consent')) {
      openStudentTeacherKidsPopup();
    } else {
      const $trackingConsentPopup = $('.tracking-consent-popup');
      const $trackingConsentCustomPopup = $(
        '.tracking-consent-customization-popup'
      );

      $trackingConsentPopup.on('closed.zf.reveal', () =>
        openStudentTeacherKidsPopup()
      );
      $trackingConsentCustomPopup.on('closed.zf.reveal', () =>
        openStudentTeacherKidsPopup()
      );
    }
  };

  if (
    studentTeacherKidsPopup &&
    !document.cookie.includes('_sofatutor_student_teacher_kids_popup')
  )
    checkCookieConsent();
};

if (
  document.body.classList.contains('welcome-page') &&
  !document.cookie.includes('_sofatutor_student_teacher_kids_popup')
)
  document.addEventListener('DOMContentLoaded', handleStudentTeacherKidsPopup);

/* eslint-disable no-undef */

document.addEventListener('DOMContentLoaded', () => {
  let animationTriggered = false;

  const animateProgress = {
    init() {
      this.cacheDom();
      this.bindEvents();
    },

    cacheDom() {
      this.$window = $(window);
      this.$progressBar = $('.js-performance-graphs-percentage-bar');
    },

    bindEvents() {
      this.$window.on('scroll.progressAnimation', () => this.animate());
    },

    animate() {
      const visibilityHelper = new Sofatutor.Classes.VisibilityHelper();

      this.$progressBar.each((_i, e) => {
        if (visibilityHelper.isVisible($(e))) {
          $(e)
            .find('.js-performance-graphs-progress')
            .addClass('is-progressed');

          if (
            this.$progressBar.find('.is-progressed').length ===
            $('.js-performance-graphs-progress').length
          ) {
            this.$window.off('scroll.progressAnimation');
          }
        }
      });
    }
  };

  if ($('.performance-graphs__percentage-bar')[0]) {
    animateProgress.init();
  }

  const animateNumber = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const number92 = document.getElementById(
    'performance-graphs__percentage--92'
  );
  const number93 = document.getElementById(
    'performance-graphs__percentage--93'
  );
  const number94 = document.getElementById(
    'performance-graphs__percentage--94'
  );

  let isElementVisible = $element => {
    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();
    let elementTop = $element.offset().top;
    let elementBottom = elementTop + $element.height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  let startAnmation = () => {
    if (animationTriggered) return;

    if ($('.js-performance-graphs-percentage-number').length) {
      if (isElementVisible($('.js-performance-graphs-percentage-number'))) {
        animateNumber(number92, 0, 92, 2000);
        animateNumber(number93, 0, 93, 2000);
        animateNumber(number94, 0, 94, 2000);
        animationTriggered = true;
      }
    }
  };

  if (isElementVisible($('.js-performance-graphs-percentage-number'))) {
    startAnmation();
  } else {
    $(window).scroll(() => {
      startAnmation();
    });
  }
});

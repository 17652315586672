/* eslint-disable no-undef */
(() => {
  const welcomeTopFeaturesCarousel = () => {
    const carousel = document.querySelector(
      '.js-welcome-top-features-carousel'
    );

    const slidesToShow = 1;

    if (carousel) {
      $(carousel).slick({
        dots: true,
        slidesToShow: 1,
        autoplay: false,
        infinite: true,
        arrows: true,
        prevArrow:
          '<button class="welcome-top-features__previous-arrow" aria-label="previous slide"><i class="icon icon--arrow-thin icon--rotate-left icon--large"></i></button>',
        nextArrow:
          '<button class="welcome-top-features__next-arrow" aria-label="next slide"><i class="icon icon--arrow-thin icon--large"></i></button>',
        centerMode: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: slidesToShow,
              dots: true
            }
          },
          {
            breakpoint: 960,
            settings: 'unslick'
          }
        ]
      });
    }
  };

  if (document.body.classList.contains('welcome-page'))
    document.addEventListener('DOMContentLoaded', welcomeTopFeaturesCarousel);
})();

(() => {
  const deeplinks = document.querySelectorAll('.deeplinks');

  if (!deeplinks[0]) {
    return;
  }

  const deeplinkLinks = document.querySelectorAll('.deeplinks__link');

  if (deeplinkLinks.length <= 8) {
    document.querySelectorAll('.js-toggle-link').forEach(link => link.remove());
    return;
  }

  document.querySelectorAll('.js-toggle-link').forEach(link => {
    link.addEventListener('click', event => {
      event.preventDefault();

      const moreButton = event.currentTarget;
      const unorderedList = moreButton.parentElement.previousElementSibling;

      if (unorderedList.classList.contains('is-opened')) {
        unorderedList.classList.remove('is-opened');
        moreButton.innerText = moreButton.dataset.langClosed;
      } else {
        unorderedList.classList.add('is-opened');
        moreButton.innerText = moreButton.dataset.langOpened;
        unorderedList.parentElement.style.display = 'none';
        unorderedList.parentElement.style.display = 'block';
      }
    });
  });
})();

/* eslint-disable no-undef */

$(function() {
  if ($('body').is('.welcome-page')) {
    // scroll to content
    $('.js-scroll-to-content-old').on('click', function(e) {
      e.preventDefault();
      const target = $('.js-scroll-target');
      return $('html, body').animate({ scrollTop: $(target).offset().top - ($('.page-header').height() || 0) - $('.js-page-banner').height() }, 300);
    });

    $('.welcome-features').on('click', '.welcome-feature__icon', e => $('html, body').animate({ scrollTop: ($(e.target).offset().top + 40) - ($('.page-header').height() || 0) - $('.js-page-banner').height() }, 300));
  }
});

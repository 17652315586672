/* eslint-disable no-undef */

$(function() {
  if ($('.js-scroll-to-content')[0]) {
    $('.js-scroll-to-content').on('click', function(e) {
      const target = $(e.currentTarget).data('feature');
      const headerHeight = $('.page-header').height();
      const deviceSize = new Sofatutor.Classes.DeviceSizeHelper();
      const scrollHelper = new Sofatutor.Classes.ScrollHelper();
      e.preventDefault();
      scrollHelper.animateScroll(
        '#welcome-feature--' + target,
        500,
        deviceSize.isDesktop() ? headerHeight : undefined
      );
    });
  }
});
